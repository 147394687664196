<template>
    <div class="popDialog">
        <el-dialog
                v-if="addDialog"
                width="95%"
                top="30px"
                :visible.sync="addDialog"
                :before-close="closeDialog"
                :fullscreen="dialogFull"
                custom-class="custom-dialog"
        >
            <template v-slot:title>
                <el-row>
                    <el-col :span="24" class="selectTitleMax">
                        <span>新增工單資料</span>
                    </el-col>
                </el-row>
            </template>
            <el-row>
                <el-col :span="24">
                    <div class="containerDiv">
                        <el-row>
                            <el-col :span="selectTitleSpan" class="selectTitle">
                                <span>公司<span class="red">*</span>:</span>
                            </el-col>
                            <el-col :span="selectInpudSpan" class="selectInpud">
                                <el-select v-model="companyId" placeholder="" @change="handleCompanyChange"
                                           :disabled="isCompanyid">
                                    <el-option :key="'placeholder'" :label="'請選擇'" :value="null"></el-option>
                                    <el-option
                                            v-for="item in companyOptions"
                                            :key="item.companyId"
                                            :label="item.companyName"
                                            :value="item.companyId"
                                            :disabled="item.disabled"
                                    >
                                    </el-option>
                                </el-select>
                            </el-col>
                            <el-col :span="selectTitleSpan" class="selectTitle">
                                <span>分店<span class="red">*</span>:</span>
                            </el-col>
                            <el-col :span="selectInpudSpan" class="selectInpud">
                                <el-select v-model="branchId" placeholder="" @change="handleBranchChange"
                                           :disabled="isBranchid">
                                    <el-option :key="'placeholder'" :label="'請選擇'" :value="null"></el-option>
                                    <el-option
                                            v-for="item in branchOptions"
                                            :key="item.branchId"
                                            :label="item.branchName"
                                            :value="item.branchId"
                                            :disabled="item.disabled"
                                    >
                                    </el-option>
                                </el-select>
                            </el-col>
                        </el-row>
                        <el-row>
                            <el-col :span="selectTitleSpan" class="selectTitle">
                                <span>會員<span class="red">*</span>:</span>
                            </el-col>
                            <el-col :span="selectInpudSpan" class="selectInpud">
                                <el-select v-model="customerId" placeholder="" @change="handleCustomerChange"
                                           :disabled="this.branchId == null" filterable
                                           :filter-method="customFilterMethod">
                                    <el-option :key="'placeholder'" :label="'請選擇'" :value="null"></el-option>
                                    <el-option
                                            v-for="item in filteredCustomerOptions"
                                            :key="item.customerId"
                                            :label="item.nickName+'-'+item.phoneNumber"
                                            :value="item.customerId"
                                            :disabled="item.disabled"
                                    >
                                    </el-option>
                                </el-select>
                            </el-col>
                            <el-col :span="selectTitleSpan" class="selectTitle">
                                <span>會員資產<span class="red">*</span>:</span>
                            </el-col>
                            <el-col :span="selectInpudSpan" class="selectInpud">
                                <el-select v-model="customerAssetsId" placeholder=""
                                           @change="handleCustomerAssetsChange" :disabled="this.customerId == null">
                                    <el-option :key="'placeholder'" :label="'請選擇'" :value="null"></el-option>
                                    <el-option
                                            v-for="item in customerAssetsOptions"
                                            :key="item.customerAssetsId"
                                            :label="item.identifier+'('+item.serviceLevel+')'"
                                            :value="item.customerAssetsId"
                                            :disabled="item.disabled"
                                    >
                                    </el-option>
                                </el-select>
                            </el-col>
                            <el-col :span="selectTitleSpan" class="selectTitle">
                                <span>工單狀態<span class="red">*</span>:</span>
                            </el-col>
                            <el-col :span="selectInpudSpan" class="selectInpud">
                                <el-select v-model="orderStatus" placeholder="">
                                    <el-option
                                            v-for="item in orderStatusOptions"
                                            :key="item.value"
                                            :label="item.label"
                                            :value="item.value"
                                            :disabled="item.disabled"
                                    >
                                    </el-option>
                                </el-select>
                            </el-col>
                        </el-row>
                        <el-row>
                            <el-col :span="selectTitleSpan" class="selectTitle">
                                <span>服務開始時間<span class="red">*</span>:</span>
                            </el-col>
                            <el-col :span="selectInpudSpan" class="selectInpud">
                                <Datepicker
                                        :inDate="inDate"
                                        :companyId="companyId"
                                        :branchId="branchId"
                                        ref="datepicker"
                                        @updateStartTimeInput="handleUpdateStartTimeInput"
                                ></Datepicker>
                            </el-col>
<!--                            <el-col :span="selectTitleSpan" class="selectTitle">-->
<!--                                <span>折扣率(%):</span>-->
<!--                            </el-col>-->
<!--                            <el-col :span="selectInpudSpan" class="selectInpud">-->
<!--                                <el-input v-model="discountPercentage" placeholder="請輸入折扣率" type="number"-->
<!--                                          :disabled="true"></el-input>-->
<!--                            </el-col>-->
                            <el-col :span="selectTitleSpan" class="selectTitle">
                                <span>折扣金額:</span>
                            </el-col>
                            <el-col :span="selectInpudSpan" class="selectInpud">
                                <el-input v-model="discountAmount" placeholder="請輸入折扣金額" type="text"
                                          @input="validateDiscountAmount"></el-input>
                            </el-col>
                        </el-row>
                        <el-row>
                            <el-col :span="selectTitleSpan" class="selectTitle">
                                <span>備註:</span>
                            </el-col>
                            <el-col :span="selectInpudSpan" class="selectInpud">
                                <el-input v-model="remark" placeholder="請輸入備註"></el-input>
                            </el-col>
                        </el-row>
                        <el-row>
                            <el-col :span="selectTitleSpan" class="selectTitle">
                                <span>總金額:</span>
                            </el-col>
                            <el-col :span="selectInpudSpan" class="selectInpud">
                                <el-input
                                        class="sum"
                                        :value="`${'$'+allSum}`"
                                        :disabled="true"
                                        :style="{visibility: allSum !== null ? 'visible' : 'hidden'}"
                                ></el-input>
                            </el-col>
                            <el-col :span="selectTitleSpan" class="selectTitle">
                                <span>支付金額:</span>
                            </el-col>
                            <el-col :span="selectInpudSpan" class="selectInpud">
                                <el-input
                                        class="sum"
                                        :value="`${'$'+discountSum}`"
                                        :disabled="true"
                                        :style="{visibility: discountSum !== null ? 'visible' : 'hidden'}"
                                ></el-input>
                            </el-col>
                            <el-col :span="selectTitleSpan" class="selectTitle">
                                <span>總工時:</span>
                            </el-col>
                            <el-col :span="selectInpudSpan" class="selectInpud">
                                <el-input
                                        class="sum"
                                        :value="`${allWorkingHours+'分鐘'}`"
                                        :disabled="true"
                                        :style="{visibility: allWorkingHours !== null ? 'visible' : 'hidden'}"
                                ></el-input>
                            </el-col>
                        </el-row>
                        <el-row>
                            <AddOrderWorkUser
                                    :assetsModelTypeId="assetsModelTypeId"
                                    :companyId="companyId"
                                    :branchId="branchId"
                                    :token="token"
                                    :mainProject="mainProject"
                                    :orderStatus="orderStatus"
                                    ref="orderWorkUser"
                                    @update:assetsModelTypeId="updateAssetsModelTypeId"
                                    @orderWorkUserAddInputs="handleOrderWorkUserAddInputs"
                            ></AddOrderWorkUser>

                            <AddOrderSubWorkUser
                                    v-if="isSubProject"
                                    :companyId="companyId"
                                    :branchId="branchId"
                                    :token="token"
                                    :orderMainWorkUserInputs="orderWorkUserInputs"
                                    :assetsModelTypeId="assetsModelTypeId"
                                    ref="orderSubWorkUser"
                                    :orderStatus="orderStatus"
                                    @orderWorkUserAddInputs="handleOrderSubWorkUserAddInputs"
                            ></AddOrderSubWorkUser>
                        </el-row>
                    </div>
                </el-col>
            </el-row>
            <div slot="footer" class="popBatton">
                <el-button @click="addTemplate" type="primary">儲存</el-button>
                <el-button @click="closeDialog">關閉</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
    import AddOrderWorkUser from '@/views/order/AddOrderWorkUser.vue'
    import AddOrderSubWorkUser from '@/views/order/AddOrderSubWorkUser.vue'
    import Datepicker from '@/components/element/Datepicker.vue'
    // import { ElAlert } from 'element-ui/types/alert'

    export default {
        name: 'addOrderDialog',
        components: {
            AddOrderWorkUser,
            Datepicker,
            AddOrderSubWorkUser,
        },
        props: {
            addDialog: null,
        },
        data() {
            return {
                dialogFull: false,
                loginReturnDto: null,
                token: null,
                companyOptions: [],
                branchOptions: [],
                customerOptions: [],
                customerAssetsOptions: [],
                workOptions: [],
                userOptions: [],
                userSubOptions: [],
                workIds: [],
                staffIds: [],
                orderStatusOptions: [
                    {value: '0', label: '未執行', disabled: false},
                    {value: '1', label: '執行中', disabled: false},
                    {value: '2', label: '完成', disabled: true},
                    {value: '3', label: '取消', disabled: true},
                ],
                companyId: null,
                branchId: null,
                customerId: null,
                customerAssetsId: null,
                orderStatus: '0',
                discountPercentage: 0,
                discountAmount: 0,
                startTime: null,
                remark: null,
                workUserList: [],
                subWorkUserList: [],
                assetsModel: null,
                reload: false,
                companyType: null,
                //是否需要公司ID
                isCompanyid: true,
                //是否需要分店ID
                isBranchid: true,
                orderWorkUserInputs: [], // 用于存储从子组件传递过来的输入框数据
                orderSubWorkUserInputs: [], // 用于存储从子组件传递过来的输入框数据
                inDate: null,
                //是否有副項目
                isSubProject: false,
                assetsModelTypeId: null,
                mainProject: true,
                searchQuery: '',
                //項目總金額
                allSum: 0,
                //折扣後金額
                discountSum: 0,
                //項目總工時
                allWorkingHours: 0,
                mainSum: 0,
                mainWorkingHoursAll: 0,
                subSum: 0,
                subWorkingHoursAll: 0,
                rolePower: null,
            }
        },
        computed: {
            filteredCustomerOptions() {
                try {
                    // Handle empty or invalid searchQuery
                    const query = this.searchQuery ? this.searchQuery.toLowerCase() : '';
                    // console.log('過濾資料 query=', query)
                    // Filter customer options based on search query
                    return this.customerOptions.filter(item => {
                        // Ensure nickName and userName are defined
                        if (!item.nickName || !item.phoneNumber) {
                            return false;
                        }
                        const combinedLabel = `${item.nickName}-${item.phoneNumber}`;
                        return combinedLabel.toLowerCase().includes(query);
                    })
                } catch (e) {
                    console.error("addOrderDialog filteredCustomerOptions 發生錯誤! e=" + e)
                    return [];
                }
            }
        },
        // watch: {
        //     searchQuery() {
        //         // Whenever searchQuery changes, update the query data
        //         this.updateSearchQuery();
        //     }
        // },
        mounted() {
        },
        methods: {
            init() {
                this.isCompanyid = JSON.parse(localStorage.getItem('isCompanyid'))
                this.isBranchid = JSON.parse(localStorage.getItem('isBranchid'))
                console.log('addCompanyDialog isCompanyid=' + this.isCompanyid)
                console.log('addCompanyDialog isBranchid=' + this.isBranchid)
                const loginReturnDto = localStorage.getItem('loginReturnDto')
                if (loginReturnDto != null && JSON.parse(loginReturnDto) != null) {
                    this.loginReturnDto = JSON.parse(loginReturnDto)
                    this.token = this.loginReturnDto.token
                    if (this.isCompanyid) {
                        this.companyId = this.loginReturnDto.companyId
                        this.companyType = this.loginReturnDto.companyType
                        console.log('addCompanyDialog取得loginReturnDto.companyId=' + this.companyId)
                    }
                }
                if (this.isBranchid) {
                    this.branchId = this.loginReturnDto.branchId
                }
                //查詢全部公司下拉選單資料
                this.searchCompanyList()
                //查詢全部分店下拉選單資料
                this.searchBranchList()
                //查詢公司會員下拉選單資料
                this.searchCustomerOptions()
                //依據條件查詢會員資產資料
                this.searchAssetsModelTypeList()
                //查詢登入者角色權利資料
                this.getRolePower()
                //取得折扣率或折扣金額
                this.getDiscount();
                //驗證是否有副項目
                this.checkIsSubProject();
                //取員工下拉選單資料
                this.getStaffOptions();
            },
            //取得折扣率或折扣金額
            async getDiscount() {
                console.log('-----開始等待 CustomerAssets-----')
                await this.waitForCustomerAssetsOptions();
                console.log('已收到資料 customerAssetsOptions=', this.customerAssetsOptions + ",customerAssetsId=" + this.customerAssetsId)
                const customerAssets = this.customerAssetsOptions.find((data) => data.customerAssetsId === this.customerAssetsId)
                // 確保找到了會員資產
                if (customerAssets) {
                    // 取得資產服務等級狀態
                    const serviceLevelStatus = customerAssets.serviceLevelStatus;
                    if(serviceLevelStatus){
                        if (customerAssets.discountPercentage != null && customerAssets.discountPercentage != '') {
                            this.discountPercentage = customerAssets.discountPercentage;
                            console.log("折扣率 discountPercentage=" + this.discountPercentage);
                        } else {
                            this.discountPercentage = 0;
                        }
                        if (customerAssets.discountAmount != null && customerAssets.discountAmount != '') {
                            this.discountAmount = customerAssets.discountAmount;
                            console.log("折扣金額 discountAmount=" + this.discountAmount);
                        } else {
                            this.discountAmount = 0;
                        }
                    }
                    console.log("資產服務等級狀態 serviceLevelStatus=" + serviceLevelStatus)
                }
            },
            waitForCustomerAssetsOptions() {
                return new Promise((resolve) => {
                    const interval = setInterval(() => {
                        if (this.customerAssetsOptions != null && this.customerAssetsOptions.length > 0 && this.customerAssetsId != null && this.customerAssetsId != '') {
                            clearInterval(interval);
                            resolve();
                        }
                    }, 10); // 每100毫秒检查一次
                });
            },
            //驗證是否有副項目
            async checkIsSubProject() {
                console.log('-----開始等待 companyOptions-----')
                await this.waitForCompanyOptions();
                console.log('已收到資料 companyOptions=', this.companyOptions + ",companyId=" + this.companyId)
                const company = this.companyOptions.find((company) => company.companyId === this.companyId)
                // 確保找到了公司資料
                if (company) {
                    // 取得該公司的 isSubProject 資料
                    this.isSubProject = company.isSubProject
                    console.log(`公司 ${company.companyName} 是否有副項目: ${this.isSubProject}`)
                } else {
                    console.log(`找不到 companyId 為 ${this.companyId} 的公司資料`)
                }
            },
            waitForCompanyOptions() {
                return new Promise((resolve) => {
                    const interval = setInterval(() => {
                        if (this.companyOptions != null && this.companyOptions.length > 0 && this.companyId != null && this.companyId != '') {
                            clearInterval(interval);
                            resolve();
                        }
                    }, 10); // 每100毫秒检查一次
                });
            },
            //取員工下拉選單資料
            async getStaffOptions() {
                console.log('-----開始等待 companyOptions && branchOptions-----')
                await this.waitForCompanyData();
                console.log('已收到資料 companyOptions=', this.companyOptions + ",branchOptions=" + this.branchOptions)
                console.log('addCompanyDialog取得服務人員 companyId=' + this.companyId + ',branchId=' + this.branchId)
                this.selectStaffOptions()
                if (this.isSubProject) {
                    this.selectStaffOptions_sub()
                }
            },
            waitForCompanyData() {
                return new Promise((resolve) => {
                    const interval = setInterval(() => {
                        if (this.companyOptions != null && this.companyOptions.length > 0 && this.branchOptions != null && this.branchOptions.length > 0) {
                            clearInterval(interval);
                            resolve();
                        }
                    }, 10); // 每100毫秒检查一次
                });
            },
            closeDialog() {
                console.log('關閉Pop')
                this.companyId = null
                this.branchId = null
                this.customerId = null
                this.customerAssetsId = null
                this.startTime = null
                this.remark = null
                this.discountPercentage = null
                this.discountAmount = null
                this.orderWorkUserInputs = []
                this.orderSubWorkUserInputs = []
                this.workUserList = []
                this.subWorkUserList = []
                this.mainProject = true
                this.isSubProject = false
                this.companyOptions = []
                this.branchOptions = []
                this.assetsModelTypeId = null
                this.$emit('update:reload', this.reload)
                this.$emit('closeDialog')
            },
            async addTemplate() {
                //新增資料
                let tmp = this
                let url = 'order/addOrder'
                try {
                    this.emitInputs()
                    this.emitInputs_sub()
                    this.setInput()
                    this.workUserList = []
                    this.subWorkUserList = []
                    console.log('新增資料 orderWorkUserInputs=', tmp.orderWorkUserInputs)
                    console.log('新增資料 orderSubWorkUserInputs=', tmp.orderSubWorkUserInputs)
                    this.setOrderWorkUser(this.orderWorkUserInputs, this.userOptions)
                    this.setOrderSubWorkUser(this.orderSubWorkUserInputs, this.userSubOptions)
                    let parameter = {
                        companyId: this.companyId,
                        branchId: this.branchId,
                        customerId: this.customerId,
                        customerAssetsId: this.customerAssetsId,
                        orderStatus: this.orderStatus,
                        discountPercentage: this.discountPercentage,
                        discountAmount: this.discountAmount,
                        startTime: this.startTime,
                        remark: this.remark,
                        workUserList: this.workUserList,
                        subWorkUserList: this.subWorkUserList,
                    }
                    console.log('新增資料 parameter=', parameter)
                    if (!this.checkAddParameter(parameter)) {
                        console.log('新增驗證資料失敗!')
                        return
                    }
                    tmp.reload = true
                    tmp.loadingView()
                    let responseData = await tmp.$api.doPostByToken(parameter, url, this.token)
                    if (responseData) {
                        tmp.$api.openMsg('新增資料完成!', 'success')
                        // 在延迟 2000 毫秒（2 秒）后执行代码
                        setTimeout(function () {
                            tmp.closeDialog()
                            //  tmp.$emit('init');
                        }, 1000)
                    } else {
                        tmp.$api.openMsg('新增資料失敗!', 'error')
                        console.log('新增資料失敗!')
                    }
                } catch (error) {
                    console.log('新增資料發生異常! error=' + error)
                } finally {
                    tmp.closeLoading()
                }
                tmp.closeLoading()
            },
            checkAddParameter(parameter) {
                console.log('儲存驗證資料! parameter=', parameter)
                var ststus = true
                if (parameter != null && parameter != '') {
                    if (parameter.companyId == null || parameter.companyId == '') {
                        console.log('公司不可為空!')
                        this.$api.openMsg('公司不可為空!', 'error')
                        return false
                    }
                    if (parameter.branchId == null || parameter.branchId == '') {
                        console.log('分店不可為空!')
                        this.$api.openMsg('分店不可為空!', 'error')
                        return false
                    }
                    if (parameter.customerId == null || parameter.customerId == '') {
                        console.log('會員不可為空!')
                        this.$api.openMsg('會員不可為空!', 'error')
                        return false
                    }
                    if (parameter.customerAssetsId == null || parameter.customerAssetsId == '') {
                        console.log('會員資產不可為空!')
                        this.$api.openMsg('會員資產不可為空!', 'error')
                        return false
                    }
                    if (parameter.orderStatus == null || parameter.orderStatus == '') {
                        console.log('工單狀態不可為空!')
                        this.$api.openMsg('工單狀態不可為空!', 'error')
                        return false
                    }
                    if (parameter.startTime == null || parameter.startTime == '') {
                        console.log('服務開始時間不可為空!')
                        this.$api.openMsg('服務開始時間不可為空!', 'error')
                        return false
                    }
                    if (parameter.workUserList == null || parameter.workUserList.length == 0) {
                        console.log('主服務項目不可為空!')
                        this.$api.openMsg('主服務項目不可為空!', 'error')
                        return false
                    } else {
                        var work = parameter.workUserList.find(
                            (orderWorkUser) => orderWorkUser.workId == null || orderWorkUser.workId == ''
                        )
                        if (work != null && work != '') {
                            console.log('主服務項目不可為空!')
                            this.$api.openMsg('主服務項目不可為空!', 'error')
                            return false
                        }
                    }
                    //主項目驗證
                    if (parameter.workUserList != null && parameter.workUserList.length > 0) {
                        //項目執行狀態為[已派工]或[執行中]時，服務人員不可為空
                        var status = true;
                        var con = 0;
                        this.workUserList.find(orderWorkUser => {
                            console.log("orderWorkUser.workStatus=" + orderWorkUser.workStatus)
                            switch (orderWorkUser.workStatus) {
                                case  "0":
                                    if (parameter.orderStatus == "1" || parameter.orderStatus == "2") {
                                        this.showErrorMessage('主項目執行狀態為[未執行]時，工單狀態選擇錯誤!')
                                        status = false;
                                    }
                                    break;
                                case  "1":
                                    if (parameter.orderStatus == "0" || parameter.orderStatus == "2") {
                                        this.showErrorMessage('主項目執行狀態為[已派工]時，工單狀態選擇錯誤!')
                                        status = false;
                                        break;
                                    }
                                    if (orderWorkUser.orderWorkUserDtoList == null || orderWorkUser.orderWorkUserDtoList.length == 0) {
                                        this.showErrorMessage('主項目執行狀態為[已派工]時，服務人員不可為空!')
                                        status = false;
                                        break;
                                    }
                                    break;
                                case  "2":
                                    if (parameter.orderStatus == "0" || parameter.orderStatus == "2") {
                                        this.showErrorMessage('主項目執行狀態為[執行中]時，工單狀態選擇錯誤!')
                                        status = false;
                                        break;
                                    }
                                    if (orderWorkUser.orderWorkUserDtoList == null || orderWorkUser.orderWorkUserDtoList.length == 0) {
                                        this.showErrorMessage('主項目執行狀態為[執行中]時，服務人員不可為空!')
                                        status = false;
                                        break;
                                    }
                                    break;
                                case  "3":
                                    if (parameter.orderStatus == "0" || parameter.orderStatus == "1") {
                                        this.showErrorMessage('主項目執行狀態為[完成]時，工單狀態選擇錯誤!')
                                        status = false;
                                        break;
                                    }
                                    if (orderWorkUser.orderWorkUserDtoList == null || orderWorkUser.orderWorkUserDtoList.length == 0) {
                                        this.showErrorMessage('主項目執行狀態為[完成]時，服務人員不可為空!')
                                        status = false;
                                        break;
                                    }
                                    break;
                                case  "4":
                                    con = con + 1;
                                    status = false;
                                    break;
                            }
                        });
                        console.log("con=" + con + ",workUserList.length=" + this.workUserList.length)
                        if (con == this.workUserList.length) {
                            if (parameter.orderStatus != "3") {
                                this.showErrorMessage('主項目執行狀態都為[取消]時，工單狀態選擇錯誤!')
                                return false
                            }
                        }
                        if (!status) {
                            return false
                        }
                    }
                    //副項目驗證
                    if (parameter.subWorkUserList != null && parameter.subWorkUserList.length > 0) {
                        const orderWorkUserData = parameter.subWorkUserList.find(
                            (orderWorkUser) => orderWorkUser.workId == null || orderWorkUser.workId == ''
                        )
                        if (orderWorkUserData != null) {
                            this.showErrorMessage('副服務項目不可為空!')
                            return false
                        }
                        //項目執行狀態為[已派工]或[執行中]時，服務人員不可為空
                        const workUserExecutingOrCompleted = parameter.subWorkUserList.find(
                            (orderWorkUser) => orderWorkUser.workStatus == "1" || orderWorkUser.workStatus == "2"
                        )
                        if (workUserExecutingOrCompleted) {
                            if (parameter.orderStatus == "0") {
                                this.showErrorMessage('副項目執行狀態為[已派工]或[執行中]時，工單狀態選擇錯誤!')
                                return false
                            }
                            if (
                                !workUserExecutingOrCompleted.orderWorkUserDtoList ||
                                workUserExecutingOrCompleted.orderWorkUserDtoList.length === 0
                            ) {
                                this.showErrorMessage('副項目執行狀態為[已派工]或[執行中]時，服務人員不可為空!')
                                return false
                            }
                        }
                        //副項目不驗證工單狀態
                        // const workUserNotDispatched = parameter.subWorkUserList.find((orderWorkUser) => orderWorkUser.workStatus == 0)
                        // if (workUserNotDispatched && (parameter.orderStatus == 1 || parameter.orderStatus == 2)) {
                        //     this.showErrorMessage('副項目執行狀態為[未派工]時，工單狀態選擇錯誤!')
                        //     return false
                        // }
                    }
                } else {
                    console.log('必填資料不可為空!')
                    this.$api.openMsg('必填資料不可為空!', 'error')
                    return false
                }
                return ststus
            },
            loadingView() {
                //開啟載入特效
                this.loadingStatus = this.$loading({
                    lock: true,
                    text: 'Loading',
                    spinner: 'el-icon-loading',
                    background: 'rgba(0, 0, 0, 0.7)',
                })
            },
            closeLoading() {
                //關閉載入特效
                if (this.loadingStatus != null) {
                    this.loadingStatus.close()
                }
            },
            async searchCompanyList() {
                //查詢全部公司下拉選單資料
                let tmp = this
                let url = 'login/search/searchCompanyOptions'
                let parameter = {
                    companyId: this.companyId,
                    companyName: '',
                    isActive: true,
                }
                //取得列表資料
                let responseData = await tmp.$api.doPost(parameter, url)
                if (responseData != null) {
                    tmp.companyOptions = responseData
                    console.log('取得全部公司下拉選單資料 companyOptions=', tmp.companyOptions)
                }
            },
            async searchBranchList() {
                //查詢全部分店下拉選單資料
                let tmp = this
                let url = 'login/search/searchBranchOptions'
                let parameter = {
                    companyId: this.companyId,
                    branchId: this.branchId,
                    companyName: '',
                    isActive: true,
                }
                //取得列表資料
                let responseData = await tmp.$api.doPostByToken(parameter, url, this.token)
                if (responseData != null) {
                    tmp.branchOptions = responseData
                    console.log('取得全部分店下拉選單資料 branchOptions=', tmp.branchOptions)
                }
            },
            async searchCustomerOptions() {
                //查詢公司會員下拉選單資料
                let tmp = this
                let url = 'consumer/search/searchCustomerOptions'
                let parameter = {
                    companyId: this.companyId,
                    branchId: this.branchId,
                    isActive: true,
                }
                //取得列表資料
                let responseData = await tmp.$api.doPostByToken(parameter, url, this.token)
                if (responseData != null) {
                    tmp.customerOptions = responseData
                    console.log('取得公司會員下拉選單資料 customerOptions=', tmp.customerOptions)
                }
            },
            async searchAssetsModelTypeList() {
                //依據條件查詢會員資產資料
                let tmp = this
                let url = 'consumer/search/assets'
                let parameter = {
                    companyId: this.companyId,
                    branchId: this.branchId,
                    customerId: this.customerId,
                    nickName: null,
                    identifier: null,
                    isActive: null,
                    remark: null,
                }
                //取得列表資料
                let responseData = await tmp.$api.doPostByToken(parameter, url, this.token)
                if (responseData != null) {
                    tmp.customerAssetsOptions = responseData
                    console.log('取得會員資產資料下拉選單 customerAssetsOptions=', tmp.customerAssetsOptions)
                }
            },
            handleCompanyChange(value) {
                //選擇公司下拉觸發
                let tmp = this
                console.log('Selected company ID:', value)
                if (!this.isCompanyid) {
                    this.companyId = value
                    this.branchId = null
                    setTimeout(function () {
                        tmp.searchBranchList()
                        // tmp.selectWorkOptions()
                        const company = tmp.companyOptions.find((company) => company.companyId === value)
                        // 確保找到了公司資料
                        if (company) {
                            // 取得該公司的 isSubProject 資料
                            tmp.isSubProject = company.isSubProject
                            console.log(`公司 ${company.companyName} 是否有副項目: ${tmp.isSubProject}`)
                        } else {
                            console.log(`找不到 companyId 為 ${tmp.companyId} 的公司資料`)
                        }
                    }, 500)
                }
            },
            handleBranchChange(value) {
                //選擇分店下拉觸發
                let tmp = this
                this.customerId = null;
                this.customerAssetsId = null;
                this.assetsModelTypeId= null;
                console.log('Selected Branch ID:', value)
                if (!this.isBranchid) {
                    this.branchId = value
                    setTimeout(function () {
                        tmp.searchCustomerOptions()
                        tmp.selectStaffOptions()
                        tmp.selectStaffOptions_sub()
                        //查詢全部主服務項目下拉選單
                        tmp.selectWorkOptions();
                        //查詢全部副服務項目下拉選單
                        tmp.selectWorkOptions_sub();
                        //觸發傳遞資料事件
                        tmp.emitInputs()
                        tmp.emitInputs_sub()
                    }, 500)
                }
            },
            handleCustomerChange(value) {
                //選擇會員下拉觸發
                let tmp = this
                this.customerAssetsId = null;
                this.searchQuery = null;
                this.assetsModelTypeId= null;
                this.customerAssetsOptions=[];
                console.log(
                    '選擇會員下拉觸發 companyId=' + this.companyId + ',branchId=' + this.branchId + ',customerId=' + value
                )
                setTimeout(function () {
                    tmp.searchAssetsModelTypeList()
                    //查詢全部主服務項目下拉選單
                    tmp.selectWorkOptions();
                    //查詢全部副服務項目下拉選單
                    tmp.selectWorkOptions_sub();
                    //觸發傳遞資料事件
                    tmp.emitInputs()
                    tmp.emitInputs_sub()
                }, 500)
            },
            handleCustomerAssetsChange(value) {
                //選擇資產下拉觸發
                let tmp = this
                console.log(
                    '選擇資產下拉觸發 companyId=' + this.companyId + ',customerAssetsId=' + value
                )
                const customerAssets = this.customerAssetsOptions.find((cu) => cu.customerAssetsId === value)
                if (customerAssets != null) {
                    this.assetsModelTypeId = customerAssets.assetsModelTypeId
                    console.log('選擇資產類別 assetsModelTypeId=' + this.assetsModelTypeId)
                }
                if (value == null || value == '') {
                    this.assetsModelTypeId = null;
                    this.discountPercentage = 0;
                    this.discountAmount = 0;
                } else {
                    this.getDiscount();
                }
                setTimeout(function () {
                    //查詢全部主服務項目下拉選單
                    tmp.selectWorkOptions();
                    //查詢全部副服務項目下拉選單
                    tmp.selectWorkOptions_sub();
                    //觸發傳遞資料事件
                    tmp.emitInputs()
                    tmp.emitInputs_sub()
                }, 100)
            },
            handleOrderWorkUserAddInputs(newInputs, userOptions, mainSum, mainWorkingHoursAll) {
                this.userOptions = userOptions
                this.orderWorkUserInputs = newInputs
                this.mainSum = mainSum;
                this.mainWorkingHoursAll = mainWorkingHoursAll;
                this.allSum = this.mainSum + this.subSum;
                this.allWorkingHours = this.mainWorkingHoursAll + this.subWorkingHoursAll;
                this.discountSumData();
                console.log('主項目 this.orderWorkUserInputs=', this.orderWorkUserInputs + ",mainSum=" + mainSum + ",mainWorkingHoursAll=" + mainWorkingHoursAll)
                console.log('主項目 this.userOptions=', this.userOptions)
            },
            handleOrderSubWorkUserAddInputs(newInputs, userSubOptions, subSum, subWorkingHoursAll) {
                this.userSubOptions = userSubOptions
                this.orderSubWorkUserInputs = newInputs
                this.subSum = subSum;
                this.subWorkingHoursAll = subWorkingHoursAll;
                this.allSum = this.mainSum + this.subSum;
                this.allWorkingHours = this.mainWorkingHoursAll + this.subWorkingHoursAll;
                this.discountSumData();
                console.log('副項目 this.orderSubWorkUserInputs=', this.orderSubWorkUserInputs + ",subSum=" + subSum + ",subWorkingHoursAll=" + subWorkingHoursAll)
                console.log('副項目 this.userSubOptions=', this.userSubOptions)
                if (this.orderSubWorkUserInputs != null && this.orderSubWorkUserInputs.length > 0) {
                    this.mainProject = false
                } else {
                    this.mainProject = true
                }
            },
            async selectWorkOptions() {
                //查詢全部主服務項目下拉選單
                await this.$refs.orderWorkUser.searchWorkOptionsList()
            },
            async selectStaffOptions() {
                //查詢員工帳號下拉選單
                await this.$refs.orderWorkUser.searchStaffOptionsList()
            },
            async emitInputs() {
                //計算金額及工時
                await this.$refs.orderWorkUser.sumData()
                //觸發傳遞資料事件
                await this.$refs.orderWorkUser.emitInputs()
            },
            async selectWorkOptions_sub() {
                //查詢全部副服務項目下拉選單
                if (this.$refs.orderSubWorkUser != null && this.$refs.orderSubWorkUser.searchWorkOptionsList() != null) {
                    await this.$refs.orderSubWorkUser.searchWorkOptionsList()
                }
            },
            async selectStaffOptions_sub() {
                //查詢員工帳號下拉選單
                await this.$refs.orderSubWorkUser.searchStaffOptionsList()
            },
            async emitInputs_sub() {
                //計算金額及工時
                await this.$refs.orderSubWorkUser.sumData()
                //觸發傳遞資料事件
                await this.$refs.orderSubWorkUser.emitInputs()
            },
            setOrderWorkUser(orderWorkUserInputs, userOptions) {
                console.log('this.orderWorkUserInputs=', orderWorkUserInputs + ',userOptions=' + userOptions)
                // 更新子件傳過來的 inputs 数据
                // if (orderWorkUserInputs == null || orderWorkUserInputs.length == 0) {
                //     this.workUserList=orderWorkUserInputs;
                // }
                // 创建一个新的数组来存储转换后的数据
                let transformedWorkUserList = orderWorkUserInputs.map((input) => {
                    let newInput = {...input} // 创建 input 的浅拷贝
                    let users = newInput.orderWorkUserDtoList // 取出 input 中的 users 数据
                    if (users && users.length > 0) {
                        let orderWorkUserDtoList = users
                            .map((order) => {
                                console.log('资料转换 setOrderWorkUser this.order=', order)
                                let data = userOptions.find((user) => user.userId === order)
                                console.log('资料转换 setOrderWorkUser this.data=', data)
                                if (!data) {
                                    return null
                                }
                                return {
                                    staffId: data.staffId,
                                    userId: data.userId,
                                }
                            })
                            .filter((user) => user !== null) // 过滤掉 null 值

                        console.log('orderWorkUserDtoList=', orderWorkUserDtoList)
                        newInput.orderWorkUserDtoList = orderWorkUserDtoList
                    }
                    return newInput
                })
                // 将转换后的数据赋值给 workUserList
                this.workUserList = transformedWorkUserList
            },
            setOrderSubWorkUser(orderSubWorkUserInputs, userOptions) {
                console.log('this.orderSubWorkUserInputs=', orderSubWorkUserInputs + ',userOptions=' + userOptions)
                // 更新子件傳過來的 inputs 数据
                // if (orderWorkUserInputs == null || orderWorkUserInputs.length == 0) {
                //     this.workUserList=orderWorkUserInputs;
                // }
                let transformedWorkUserList = orderSubWorkUserInputs.map((input) => {
                    let newInput = {...input} // 创建 input 的浅拷贝
                    let users = newInput.orderWorkUserDtoList // 取出 input 中的 users 数据
                    if (users && users.length > 0) {
                        let orderWorkUserDtoList = users
                            .map((order) => {
                                console.log('资料转换 setOrderSubWorkUser this.order=', order)
                                let data = userOptions.find((user) => user.userId === order)
                                console.log('资料转换 setOrderSubWorkUser this.data=', data)
                                if (!data) {
                                    return null
                                }
                                return {
                                    staffId: data.staffId,
                                    userId: data.userId,
                                }
                            })
                            .filter((user) => user !== null) // 过滤掉 null 值

                        console.log('orderWorkUserDtoList=', orderWorkUserDtoList)
                        newInput.orderWorkUserDtoList = orderWorkUserDtoList
                    }
                    return newInput
                })
                // 将转换后的数据赋值给 workUserList
                this.subWorkUserList = transformedWorkUserList
            },
            handleUpdateStartTimeInput(newInput) {
                // 更新日期數據
                this.startTime = newInput
                console.log('更新日期數據 startTime=' + this.startTime)
            },
            updateAssetsModelTypeId(newId) {
                console.log('updateAssetsModelTypeId newId=' + newId)
                //主項目公司下拉選單觸動
                //父項目傳過來的資料
                // let tmp = this
                // tmp.assetsModelTypeId = newId
                // console.log('更新參數值 assetsModelTypeId=' + tmp.assetsModelTypeId)
                // console.log('是否有副項目 isSubProject=' + tmp.isSubProject)
                // //有副項目
                // if (tmp.isSubProject) {
                //     //刷新副項目資料
                //     setTimeout(function () {
                //         tmp.selectWorkOptions_sub()
                //     }, 500)
                // }
            },
            async setInput() {
                //觸發日期資料傳送
                console.log('觸發日期資料傳送 startTime=' + this.startTime)
                await this.$refs.datepicker.setInput()
            },
            showErrorMessage(message) {
                console.log(message)
                this.$api.openMsg(message, 'error')
            },
            async getRolePower() {
                //查詢登入者角色權利資料
                let tmp = this
                let url = 'rolePower/getRolePower'
                //取得列表資料
                let responseData = await tmp.$api.doGetByToken(url, this.token)
                if (responseData != null) {
                    tmp.rolePower = responseData
                    console.log('查詢登入者角色權利資料 rolePower=', tmp.rolePower)
                }
            },
            updateSearchQuery() {
                //檢索資產編號
                // Update search query for filtering
                console.log('檢索的資料 searchQuery=', this.searchQuery)
                let queryData = "";
                try {
                    if (this.customerAssetsOptions != null && this.customerAssetsOptions.length > 0 && this.searchQuery != null && this.searchQuery != '') {
                        // const customerAssets = this.customerAssetsOptions.find((data) => data.identifier.toLowerCase().includes(query.toLowerCase()))
                        let customerAssets = null;
                        this.customerAssetsOptions.find(data => {
                            if (data.identifier != null && data.identifier != '') {
                                if (data.identifier.toLowerCase().includes(this.searchQuery.toLowerCase())) {
                                    customerAssets = data;
                                    console.log('檢索到客戶資產編號 identifier=', data.identifier)
                                }
                            }
                        });
                        if (customerAssets != null) {
                            console.log('檢索到客戶資產資料 customerAssets=', customerAssets)
                            const customer = this.customerOptions.find((data) => data.customerId === customerAssets.customerId)
                            if (customer != null) {
                                console.log('檢索到客戶資料 customer=', customer)
                                queryData = customer.phoneNumber;
                            }
                        }
                    }
                    if (queryData != '') {
                        this.searchQuery = queryData;
                        console.log('檢索到客戶電話 searchQuery=', this.searchQuery)
                    }
                } catch (e) {
                    console.error("updateSearchQuery 發生錯誤! e=" + e)
                }
            },
            customFilterMethod(query) {
                this.searchQuery = query;
                //檢索資產編號
                // this.updateSearchQuery();
            },
            discountSumData() {
                if (this.allSum != null) {
                    if (this.discountAmount != null && this.discountAmount != 0) {
                        this.discountSum = this.allSum - this.discountAmount;
                    } else if (this.discountPercentage != null) {
                        this.discountSum = Math.round(this.allSum * (1 - this.discountPercentage / 100));
                    } else {
                        this.discountSum = this.allSum;
                    }
                }
                console.log("支付金額 discountSum=" + this.discountSum)
                // 确保支付金额为正数
                if (this.discountSum <= 0) {
                    this.discountSum = 0;
                }
            },
            validateDiscountAmount(value) {
                console.log("validateDiscountAmount called"); // 调试信息
                // 移除非数字字符
                let sanitizedValue = value.replace(/[^0-9]/g, '');
                console.log("移除非数字字符 value=" + value + ",sanitizedValue=" + sanitizedValue)
                // 如果值为空，或者是0或负数，清空输入框
                if (sanitizedValue != null && sanitizedValue != '' && sanitizedValue.length > 0) {
                    if (sanitizedValue.length > 1 && sanitizedValue.charAt(0) === '0') {
                        sanitizedValue = sanitizedValue.substring(1);
                    }
                    if (this.rolePower != null && this.rolePower.maximumDiscountAmount != null && sanitizedValue > this.rolePower.maximumDiscountAmount) {
                        console.log("本角色給予的折扣最高額度 rolePower.maximumDiscountAmount=" + this.rolePower.maximumDiscountAmount)
                        this.discountAmount = 0;
                        this.showErrorMessage('已超過本角色給予的折扣最高額度!')
                    } else {
                        this.discountAmount = sanitizedValue;
                    }
                } else {
                    this.discountAmount = 0;
                }
                this.discountSumData();
            },
        },
    }
</script>

<style scoped>
    img {
        width: 50px;
        height: 50px;
        /*height: auto;*/
        margin: 10px 0px 0px 0px;
    }

    /**日期選擇器**/
    .selectInpud .el-date-editor {
        width: 100%;
    }

    /* 使用 ::v-deep 深度选择器 */
    .sum ::v-deep .el-input__inner {
        border: none !important; /* 去除外框线 */
        background-color: transparent !important; /* 设置背景色为透明 */
        padding: 0 !important; /* 去除内部的 padding */
        box-shadow: none !important; /* 去除阴影效果 */
    }
</style>
