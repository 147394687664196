<template>
    <div>
        <el-row>
            <el-col :span="24" class="selectInpud">
                <el-button @click="addInput">+副項目</el-button>
                <br><span>副項目金額小計:{{subSum}}</span><br><span>副項目工時小計:{{subWorkingHoursAll}}</span>
            </el-col>
        </el-row>
        <el-row v-for="(input, index) in inputs" :key="index" class="addInputStyle">
            <el-row>
                <button @click="removeInput(index)" class="el-dialog__headerbtn">
                    <i class="el-dialog__close el-icon el-icon-close"></i>
                </button>
                <el-col :span="selectTitleSpan" class="selectTitle"><span>副服務項目:</span></el-col>
                <el-col :span="selectInpudSpan" class="selectInpud">
                    <el-select v-model="input.workId" placeholder="" :disabled="assetsModelTypeId == null"
                               @change="handleWorkChange" filterable :filter-method="customFilterMethod">
                        <el-option :key="'placeholder'" :label="'請選擇'" :value="null"></el-option>
                        <el-option
                                v-for="item in filteredCustomerOptions"
                                :key="item.workId"
                                :label="item.projectType+'-$'+item.amount"
                                :value="item.workId"
                                :disabled="item.disabled"
                        >
                        </el-option>
                    </el-select>
                </el-col>
                <el-col :span="selectTitleSpan" class="selectTitle">
                    <span>項目執行狀態:</span>
                </el-col>
                <el-col :span="selectInpudSpan" class="selectInpud">
                    <el-select v-model="input.workStatus" placeholder="" @change="handleWorkStatusChange(input)">
                        <el-option
                                v-for="item in workStatusOptions"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value"
                                :disabled="item.disabled"
                        >
                        </el-option>
                    </el-select>
                </el-col>
            </el-row>
            <el-row>
                <el-col :span="selectTitleSpan" class="selectTitle">
                    <span>服務人員:</span>
                </el-col>
                <el-col :span="selectInpudSpan" class="selectInpud">
                    <span v-if="userOptions == null || userOptions.length == 0">待查詢</span>
                    <div
                            style="border: 2px solid #d3d5dd; background-color: #f5f7fa; height: 100px; overflow-y: auto"
                            v-if="userOptions != null && userOptions.length > 0"
                    >
                        <el-checkbox-group v-model="input.orderWorkUserDtoList">
                            <div v-for="user in userOptions" :key="user.staffId" @change="handleChange">
                                <el-checkbox :label="user.userId" :disabled="input.workStatus == '0'">{{ user.nickName
                                    }}
                                </el-checkbox>
                            </div>
                        </el-checkbox-group>
                    </div>
                    <!--                -->
                    <!--                <el-transfer v-model="input.orderWorkUserDtoList"-->
                    <!--                             :titles="['待選服務人員', '已選服務人員']"-->
                    <!--                             @change="handleChange"-->
                    <!--                             :props="{-->
                    <!--                                     key: 'staffId',-->
                    <!--                                     label: 'nickName',-->
                    <!--                                     value: 'userId'-->
                    <!--                                     }"-->
                    <!--                             :data="userOptions"-->
                    <!--                             class="custom-transfer">-->
                    <!--                </el-transfer>-->
                </el-col>
                <!-- <el-col :span="selectInpudSpan" class="selectInpud">
                    <el-button @click="removeInput(index)">X</el-button>
                </el-col> -->
            </el-row>
        </el-row>
    </div>
</template>

<script>
    export default {
        name: 'addOrderSubWorkUser',
        props: {
            companyId: null,
            branchId: null,
            token: null,
            assetsModelTypeId: null,
            orderMainWorkUserInputs: null,
            orderStatus: null,
            customerAssetsId: null,
        },
        data() {
            return {
                // inputs: [{
                //     workId: null, workStatus: "0", orderWorkUserDtoList: []
                // }],
                inputs: [],
                workOptions: [],
                userOptions: [],
                workStatusOptions: [
                    {value: '0', label: '未派工', disabled: false},
                    {value: '1', label: '已派工', disabled: false},
                    {value: '2', label: '執行中', disabled: false},
                    {value: '3', label: '完成', disabled: true},
                    {value: '4', label: '取消', disabled: true},
                ],
                searchQuery: null,
                subSum: 0,
                subWorkingHoursAll:0,
            }
        },
        computed: {
            filteredCustomerOptions() {
                try {
                    // Handle empty or invalid searchQuery
                    const query = this.searchQuery ? this.searchQuery.toLowerCase() : '';
                    // console.log('過濾資料 query=', query)
                    // Filter customer options based on search query
                    return this.workOptions.filter(item => {
                        // Ensure nickName and userName are defined
                        if (!item.projectType) {
                            return false;
                        }
                        return item.projectType.toLowerCase().includes(query);
                    })
                } catch (e) {
                    console.error("addOrderSubWorkUser filteredCustomerOptions 發生錯誤! e=" + e)
                    return [];
                }
            }
        },
        mounted() {
        },
        methods: {
            init() {
                this.workOptions = []
                this.userOptions = []
                this.searchWorkOptionsList()
                this.searchStaffOptionsList()
            },
            customFilterMethod(query) {
                this.searchQuery = query;
            },
            handleWorkChange(value) {
                console.log('選中的資料value：', value)
                this.searchQuery = null;
                this.sumData()
                this.emitInputs()
            },
            sumData(){
                this.subSum=0;
                this.subWorkingHoursAll=0;
                console.log('已收到資料 inputs=', this.inputs + ",workOptions=" + this.workOptions);
                this.inputs.find(data => {
                    if (data.workId != null && data.workId != '' && this.workOptions != null && this.workOptions.length > 0) {
                        const work = this.workOptions.find((workData) => workData.workId === data.workId)
                        if(work != null){
                            this.subSum+=work.amount;
                            this.subWorkingHoursAll+=work.workingHours;
                        }
                    }
                });
            },
            addInput() {
                if (this.orderMainWorkUserInputs != null && this.orderMainWorkUserInputs.length > 1) {
                    this.$api.openMsg('主項目只能選擇一種!', 'error')
                    return
                }
                // 添加一个新的输入框状态到数组中，每个输入框状态包含一个 value 字段
                // this.inputs.push({workId: null, workStatus: null, orderWorkUserDtoList: []});
                // 深拷貝來確保每次新增的表單行不會相互影響
                this.inputs.push(
                    JSON.parse(
                        JSON.stringify({
                            workId: null,
                            workStatus: '0',
                            orderWorkUserDtoList: [],
                        })
                    )
                )
                this.sumData()
                this.emitInputs()
            },
            removeInput(index) {
                // 从数组中移除指定的输入框
                this.inputs.splice(index, 1)
                this.sumData()
                this.emitInputs()
            },
            emitInputs() {
                this.$emit('orderWorkUserAddInputs', this.inputs, this.userOptions,this.subSum,this.subWorkingHoursAll)
                console.log('emitInputs=', this.inputs, this.userOptions)
            },
            async searchWorkOptionsList() {
                console.log('副項目-開始查詢全部服務項目下拉選單資料')
                console.log('副項目-查詢全部服務項目下拉選單資料 companyId=' + this.companyId + ',assetsModelTypeId=' + this.assetsModelTypeId)
                if (this.inputs != null) {
                    this.inputs.forEach(input => {
                        input.workId = null;
                    });
                }
                this.workOptions = []
                if (this.assetsModelTypeId == null || this.assetsModelTypeId == '') {
                    return;
                }
                //查詢全部服務項目資料
                let tmp = this
                let url = 'work/search/workOptions'
                let parameter = {
                    companyId: this.companyId,
                    isActive: true,
                    assetsModelTypeId: this.assetsModelTypeId,
                    isMainProject: false,
                }
                //取得列表資料
                let responseData = await tmp.$api.doPostByToken(parameter, url, this.token)
                if (responseData != null) {
                    tmp.workOptions = responseData
                    console.log('副項目-取得全部服務項目下拉選單資料 workOptions=' + tmp.workOptions)
                }
            },
            async searchStaffOptionsList() {
                console.log('副項目-開始查詢員工帳號下拉選單資料')
                console.log('副項目-查詢員工帳號下拉選單資料 companyId=' + this.companyId + ',branchId=' + this.branchId)
                this.orderWorkUserDtoList = []
                this.userOptions = []
                //查詢員工帳號下拉選單資料
                let tmp = this
                let url = 'staff/search/staffOptions'
                let parameter = {
                    companyId: this.companyId,
                    branchId: this.branchId,
                    isActive: true,
                    role: null,
                }
                //取得列表資料
                let responseData = await tmp.$api.doPostByToken(parameter, url, this.token)
                if (responseData != null) {
                    tmp.userOptions = responseData
                    console.log('副項目-取得員工帳號下拉選單資料 userOptions=' + tmp.userOptions)
                }
            },
            handleChange(value) {
                // 處理選擇變化的方法
                console.log('選中的資料userId：', value)
            },
            handleWorkStatusChange(input) {
                console.log('選中的資料workStatus：', input.workStatus)
                if (input.workStatus != '0') {
                    if (this.orderStatus === '0') {
                        this.$api.openMsg('工單狀態選擇錯誤!', 'error')
                    }
                }
                if (input.workStatus === '0') {
                    input.orderWorkUserDtoList = []
                }
            },
        },
    }
</script>

<style scoped>
    .addInputStyle {
        border: 2px solid #89b88b;
        background-color: #F5F7FA;
        margin-top: 5px;
        /*text-align: left;*/
        /*margin: 20px 0px 15px 15px;*/
        /*!*margin-bottom: 10px; !* 可选的样式，用于分隔输入框 *!*!*/
        /*display: flex; !* 使输入框和按钮在同一行 *!*/
        /*align-items: center;*/
    }

    /*.addInputStyle .el-input {*/
    /*    flex: 1; !* 输入框占满剩余空间 *!*/
    /*}*/
</style>
