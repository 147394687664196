<template>
    <div class="p1Div">
        <el-row>
            <el-col :span="24">
                <MenuPath></MenuPath>
            </el-col>
        </el-row>
        <div class="containerDiv">
            <el-row>
                <el-col :span="selectTitleSpan" class="selectTitle">
                    <span>公司:</span>
                </el-col>
                <el-col :span="selectInpudSpan" class="selectInpud">
                    <el-select v-model="companyId" placeholder="" @change="handleCompanyChange" :disabled="isCompanyid">
                        <el-option :key="'placeholder'" :label="'請選擇'" :value="null"></el-option>
                        <el-option
                                v-for="item in companyOptions"
                                :key="item.companyId"
                                :label="item.companyName"
                                :value="item.companyId"
                                :disabled="item.disabled"
                        >
                        </el-option>
                    </el-select>
                </el-col>
                <el-col :span="selectTitleSpan" class="selectTitle">
                    <span>分店:</span>
                </el-col>
                <el-col :span="selectInpudSpan" class="selectInpud">
                    <el-select v-model="branchId" placeholder="" :disabled="isBranchid">
                        <el-option :key="'placeholder'" :label="'請選擇'" :value="null"></el-option>
                        <el-option
                                v-for="item in branchOptions"
                                :key="item.branchId"
                                :label="item.branchName"
                                :value="item.branchId"
                                :disabled="item.disabled"
                        >
                        </el-option>
                    </el-select>
                </el-col>
            </el-row>
            <el-row>
                <el-col :span="selectTitleSpan" class="selectTitle">
                    <span>開始時間:</span>
                </el-col>
                <el-col :span="selectInpudSpan" class="selectInpud">
                    <el-date-picker v-model="startDate" type="date" placeholder="選擇日期和時間"></el-date-picker>
                </el-col>
                <el-col :span="selectTitleSpan" class="selectTitle">
                    <span>結束時間:</span>
                </el-col>
                <el-col :span="selectInpudSpan" class="selectInpud">
                    <el-date-picker v-model="endDate" type="date" placeholder="選擇日期和時間"></el-date-picker>
                </el-col>
            </el-row>
            <el-row>
                <el-col :span="selectTitleSpan" class="selectTitle">
                    <span>搜索條件:</span>
                </el-col>
                <el-col :span="selectInpudSpan" class="selectInpud">
                    <el-input v-model="nickName" placeholder="請輸入暱稱/資產識別碼/聯絡電話"></el-input>
                </el-col>
                <el-col :span="selectTitleSpan" class="selectTitle">
                    <span>工單狀態:</span>
                </el-col>
                <el-col :span="selectInpudSpan" class="selectInpud">
                    <el-select v-model="orderStatus" placeholder="">
                        <el-option :key="'placeholder'" :label="'請選擇'" :value="null"></el-option>
                        <el-option
                                v-for="item in orderStatusOptions"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value"
                                :disabled="item.disabled"
                        >
                        </el-option>
                    </el-select>
                </el-col>
            </el-row>
            <el-row>
                <el-col :span="selectBattonSpan" class="selectBatton">
                    <div class="grid-content bg-purple-light">
                        <el-button @click="searchEntityList()" type="primary" size="medium">查詢</el-button>
                    </div>
                </el-col>
                <el-col :span="selectBattonSpan" class="selectBatton">
                    <div class="grid-content bg-purple-light">
                        <el-button @click="clearData()" type="primary" size="medium">清除</el-button>
                    </div>
                </el-col>
            </el-row>
        </div>
        <el-row>
            <el-col :span="4" class="addBatton">
                <div class="grid-content bg-purple-light">
                    <el-button v-if="addButton" @click="add()" type="primary" size="medium">新增工單</el-button>
                </div>
            </el-col>
        </el-row>
        <el-row>
            <el-col :span="24">
                <TableTemplate
                        ref="tableTemplate"
                        :tableData="tableData"
                        :columns="columns"
                        :total="total"
                        :loading="loading"
                        :defaultsort="defaultsort"
                        :currentPage="currentPage"
                        :pageSize="pageSize"
                        :direction="direction"
                        :orderByName="orderByName"
                        :checkBoxShow="checkBoxShow"
                        :inDetailedButton="inDetailedButton"
                        :inEditButton="inEditButton"
                        :inDeleteButton="inDeleteButton"
                        @getTableDataList="findEntityList"
                        @editClick="editClick"
                        @deleteClick="deleteClick"
                        @detailedClick="detailedClick"
                ></TableTemplate>
            </el-col>
        </el-row>
        <EditDialog
                :editDialog="editDialog"
                @closeDialog="closeDialog"
                @init="init"
                ref="editDialog"
                @update:reload="handleReloadUpdate"
        ></EditDialog>
        <AddDialog
                :addDialog="addDialog"
                @closeDialog="closeDialog"
                @init="init"
                ref="addDialog"
                @update:reload="handleReloadUpdate"
        ></AddDialog>
        <DetailedDialog
                :detailedDialog="detailedDialog"
                @closeDialog="closeDialog"
                @init="init"
                @selectOrderWorkUser="selectOrderWorkUser"
                ref="detailedDialog"
                @update:reload="handleReloadUpdate"
        ></DetailedDialog>
        <DetailedOrderWorkUser
                :orderWorkUserDialog="orderWorkUserDialog"
                @orderWorkUserCloseDialog="orderWorkUserCloseDialog"
                ref="orderWorkUserDialog"
                :orderWorkUserData="orderWorkUserData"
        ></DetailedOrderWorkUser>
    </div>
</template>

<script>
    import TableTemplate from '@/components/element/TableTemplate.vue'
    import MenuPath from '@/components/element/MenuPath.vue'
    import tableColumns from '@/assets/js/orderHomeTable.js'
    import EditDialog from '@/views/order/EditOrderDialog.vue'
    import AddDialog from '@/views/order/AddOrderDialog.vue'
    import DetailedDialog from '@/views/order/DetailedOrderDialog.vue'
    import DetailedOrderWorkUser from '@/views/order/DetailedOrderWorkUserDialog.vue'

    export default {
        name: 'orderHome',
        components: {
            MenuPath,
            TableTemplate,
            EditDialog,
            AddDialog,
            DetailedDialog,
            DetailedOrderWorkUser,
        },
        data() {
            return {
                //表頭設定
                columns: [],
                //table數據
                tableData: [],
                //總筆數
                total: 0,
                //抽成總佣金
                totalCommission: 0,
                //是否啟用載入動畫效果
                loading: false,
                //預設排序
                defaultsort: {prop: 'createDate', order: 'descending'},
                // 当前页
                currentPage: 1,
                //每页多少条
                pageSize: 10,
                //排序:ASC,DESC
                direction: 'DESC',
                //依據屬性欄位排序
                orderByName: 'createDate',
                checkBoxShow: false,
                editDialog: false,
                addDialog: false,
                detailedDialog: false,
                orderWorkUserDialog: false,
                token: null,
                companyOptions: [],
                branchOptions: [],
                orderStatusOptions: [
                    {value: '0', label: '未派工'},
                    {value: '1', label: '執行中'},
                    {value: '2', label: '完成'},
                    {value: '3', label: '取消'},
                ],
                orderId: null,
                companyId: null,
                branchId: null,
                staffId: null,
                role: null,
                nickName: null,
                startDate: this.getDateDaysAgo(7),
                endDate: this.getDateDaysLater(7),
                orderStatus: null,
                loginReturnDto: null,
                addButton: null,
                //按鈕
                inDetailedButton: null,
                inEditButton: null,
                inDeleteButton: null,
                reload: false,
                permissionControlList: null,
                page: 'ORDER',
                functionCodes: ['ADD', 'QRY', 'EDIT', 'DEL', 'VIEW'],
                //是否需要公司ID
                isCompanyid: true,
                //是否需要分店ID
                isBranchid: true,
                orderWorkUserData: [],
            }
        },

        mounted() {
            this.columns = tableColumns
            this.init()
        },
        methods: {
            init() {
                this.isCompanyid = JSON.parse(localStorage.getItem('isCompanyid'))
                this.isBranchid = JSON.parse(localStorage.getItem('isBranchid'))
                console.log('commissionHome取得isCompanyid=' + this.isCompanyid)
                console.log('commissionHome取得isBranchid=' + this.isBranchid)
                // 從 Local Storage 獲取 loginReturnDto
                this.loginReturnDto = localStorage.getItem('loginReturnDto')
                if (this.loginReturnDto != null && JSON.parse(this.loginReturnDto) != null) {
                    this.loginReturnDto = JSON.parse(this.loginReturnDto)
                    this.token = this.loginReturnDto.token
                    this.role = this.loginReturnDto.role
                    if (this.isCompanyid) {
                        this.companyId = this.loginReturnDto.companyId
                        console.log('commissionHome取得loginReturnDto.companyId=' + this.companyId)
                    }
                    if (this.isBranchid) {
                        this.branchId = this.loginReturnDto.branchId
                        console.log('commissionHome取得loginReturnDto.branchId=' + this.branchId)
                    }
                } else {
                    console.log('commissionHome未取得token資料，回到登入頁面!')
                    this.$api.openMsg('尚未登入完成，請重新登入!', 'error')
                    this.$router.push({path: '/login'})
                    return
                }
                //權限判斷
                this.checkPermission()
                this.searchCompanyList()
                this.searchBranchList()
                this.currentPage = 1
                this.pageSize = 10
                this.findEntityList(this.currentPage, this.pageSize, this.orderByName, this.direction)
            },
            searchEntityList() {
                this.findEntityList(this.currentPage, this.pageSize, this.orderByName, this.direction)
            },
            getDateDaysAgo(days) {
                const date = new Date();
                date.setDate(date.getDate() - days);
                date.setHours(0, 0, 0, 0);
                return date;
            },
            getDateDaysLater(days) {
                const date = new Date();
                date.setDate(date.getDate() + days);
                date.setHours(0, 0, 0, 0);
                return date;
            },
            checkPermission() {
                //權限判斷
                const permissionControl = localStorage.getItem('permissionControl')
                if (permissionControl != null && JSON.parse(permissionControl) != null) {
                    this.permissionControlList = JSON.parse(permissionControl)
                    console.log('companyHome取得permissionControlList=' + this.permissionControlList.length)
                }
                if (this.permissionControlList != null && this.permissionControlList.length > 0) {
                    const results = this.$api.findAndCategorizePermissions(
                        this.permissionControlList,
                        this.page,
                        this.functionCodes
                    )
                    this.addButton = this.$api.hasDataForSpecificFunctionCode(results, 'ADD') ? true : false
                    this.qryButton = this.$api.hasDataForSpecificFunctionCode(results, 'QRY') ? true : false
                    this.inEditButton = this.$api.hasDataForSpecificFunctionCode(results, 'EDIT') ? true : false
                    this.inDeleteButton = this.$api.hasDataForSpecificFunctionCode(results, 'DEL') ? true : false
                    this.inDetailedButton = this.$api.hasDataForSpecificFunctionCode(results, 'VIEW') ? true : false
                }
                console.log('commissionHome取得addButton=' + this.addButton)
                console.log('commissionHome取得qryButton=' + this.qryButton)
                console.log('commissionHome取得inEditButton=' + this.inEditButton)
                console.log('commissionHome取得inDeleteButton=' + this.inDeleteButton)
                console.log('commissionHome取得inDetailedButton=' + this.inDetailedButton)
                if (!this.inEditButton && !this.inDetailedButton && !this.inDeleteButton) {
                    //按鈕都隱藏時，不顯示操作欄位
                    this.columns = this.columns.filter((column) => column.prop !== 'action')
                }
            },
            async findEntityList(currentPage, pageSize, orderByName, direction) {
                /**
                 * vue axios 同步請求
                 * **/
                console.log('findEntityList in .....')
                console.log('currentPage=' + currentPage)
                console.log('pageSize=' + pageSize)
                console.log('orderByName=' + orderByName)
                console.log('direction=' + direction)
                if (!this.qryButton) {
                    console.log('登入者無查詢權限!')
                    this.$api.openMsg('登入者無查詢權限!', 'error')
                    return
                }
                this.currentPage = currentPage
                this.pageSize = pageSize
                this.orderByName = orderByName
                this.direction = direction
                this.loading = true
                let tmp = this
                let url = 'order/search/searchOrderList'
                let parameter = {
                    companyId: tmp.companyId,
                    branchId: tmp.branchId,
                    orderStatus: tmp.orderStatus,
                    startTime: tmp.startDate,
                    endTime: tmp.endDate,
                    nickName: tmp.nickName,
                    pageNo: tmp.currentPage,
                    dataSize: tmp.pageSize,
                    orderByName: tmp.orderByName,
                    direction: tmp.direction,
                }
                //取得列表資料
                let responseData = await tmp.$api.doPostByToken(parameter, url, this.token)
                if (responseData != null) {
                    tmp.tableData = responseData
                    if (tmp.tableData != null && tmp.tableData.length > 0) {
                        tmp.total = tmp.tableData[0].totalRecords
                        console.log('tableData=', tmp.tableData)
                        console.log('total=' + tmp.total)
                    } else {
                        tmp.total = 0
                        console.log('total=' + tmp.total)
                    }
                }
                this.loading = false
                console.log('asyncTest----end')
            },
            clearData() {
                //清除查詢資料
                if (this.isCompanyid) {
                    this.companyId = this.loginReturnDto.companyId
                    console.log('commissionHome取得loginReturnDto.companyId=' + this.companyId)
                } else {
                    this.companyId = null
                }
                if (this.isBranchid) {
                    this.branchId = this.loginReturnDto.branchId
                    console.log('commissionHome取得loginReturnDto.branchId=' + this.branchId)
                } else {
                    this.branchId = null
                }
                this.nickName = null
                this.startDate = null
                this.endDate = null
                this.orderStatus = null
            },
            closeDialog() {
                this.editDialog = false
                this.addDialog = false
                this.detailedDialog = false
                console.log('reload=' + this.reload)
                //判斷是否需要重載畫面
                if (this.reload) {
					this.searchEntityList();
                   // window.location.reload()
                }
            },
            orderWorkUserCloseDialog() {
                this.orderWorkUserDialog = false
            },
            editClick(row) {
                console.log('orderHome 編輯 row=', row)
                if (!this.inEditButton) {
                    this.$api.openMsg('未具有權限!', 'error')
                    return
                }
                if (row.orderStatus === 2) {
                    this.$api.openMsg('此工單已完成，無法再次編輯!', 'error')
                    return
                }
                if (row.orderStatus === 3) {
                    this.$api.openMsg('此工單已取消，無法再次編輯!', 'error')
                    return
                }
                //編輯
                this.$refs.editDialog.init(row)
                this.editDialog = true
            },
            detailedClick(row) {
                //詳細頁
                console.log('orderHome 詳細頁 row=', row)
                this.$refs.detailedDialog.init(row)
                this.detailedDialog = true
            },
            async deleteClick(row) {
                //刪除
                console.log('orderHome 刪除 row=', row)
            },
            add() {
                console.log('orderHome 新增...role=' + this.role)
                this.$refs.addDialog.init()
                this.addDialog = true
            },
            handleReloadUpdate(newValue) {
                this.reload = newValue
            },
            async searchCompanyList() {
                //查詢全部公司下拉選單資料
                let tmp = this
                let url = 'login/search/searchCompanyOptions'
                let parameter = {
                    companyId: this.companyId,
                    companyName: '',
                    isActive: true,
                }
                //取得列表資料
                let responseData = await tmp.$api.doPost(parameter, url)
                if (responseData != null) {
                    tmp.companyOptions = responseData
                    console.log('取得下拉選單資料 companyOptions=' + tmp.companyOptions)
                }
            },
            async searchBranchList() {
                //查詢全部分店下拉選單資料
                let tmp = this
                let url = 'login/search/searchBranchOptions'
                let parameter = {
                    companyId: this.companyId,
                    branchId: this.branchId,
                    branchName: '',
                    isActive: true,
                }
                //取得列表資料
                let responseData = await tmp.$api.doPostByToken(parameter, url, this.token)
                if (responseData != null) {
                    tmp.branchOptions = responseData
                    console.log('取得下拉選單資料 branchOptions=' + tmp.branchOptions)
                }
            },
            handleCompanyChange(value) {
                console.log('Selected company ID:', value)
                console.log('staffHome取得isCompanyid=' + this.isCompanyid)
                //系統管理員權限時會查詢一次該公司所屬的分店
                if (!this.isCompanyid) {
                    this.companyId = value
                    this.branchId = null
                    this.searchBranchList()
                }
            },
            async selectOrderWorkUser(row) {
                console.log('orderHome selectOrderWorkUser row=', row)
                let tmp = this
                let url = 'order/search/selectOrderWorkUser'
                let parameter = {
                    orderId: row.orderId,
                    workId: row.workId,
                    isMainProject: null,
                }
                //取得列表資料
                let responseData = await tmp.$api.doPostByToken(parameter, url, this.token)
                if (responseData != null) {
                    tmp.orderWorkUserData = responseData
                    tmp.orderWorkUserDialog = true
                    console.log('orderWorkUserDialog=', tmp.orderWorkUserDialog)
                    // let nickNames = responseData.map(item => item.nickName).join(",");
                    // tmp.$api.openMsg("本項目服務人員有: " + nickNames, "success");
                }
            },
        },
    }
</script>

<style>
    .p1Div {
        width: 99%;
    }

    /**日期選擇器**/
    .selectInpud .el-date-editor {
        width: 100%;
    }

    /* 隱藏 el-date-picker 的時鐘圖標 */
    .el-date-editor .el-input__prefix {
        display: none;
    }
</style>
