export default [
	{
		prop: 'orderId',
		label: '工單號',
		minWidth: '150', //列寬
		showOverflowTooltip: false, //設置表格文字過長顯示省略號
		align: 'center', //對齊方式
		sortable: true,
	},
	{
		prop: 'companyName',
		label: '公司名稱',
		minWidth: '150', //列寬
		showOverflowTooltip: false, //設置表格文字過長顯示省略號
		align: 'center', //對齊方式
		sortable: true,
	},
	{
		prop: 'branchName',
		label: '分店名稱',
		minWidth: '150',
		showOverflowTooltip: false,
		align: 'center',
		sortable: true,
	},
	{
		prop: 'nickName',
		label: '暱稱',
		minWidth: '150',
		showOverflowTooltip: false,
		align: 'center',
		sortable: true,
	},
	{
		prop: 'identifier',
		label: '資產識別碼',
		minWidth: '150',
		showOverflowTooltip: false,
		align: 'center',
		sortable: true,
	},
	{
		prop: 'orderStatusString',
		label: '工單狀態',
		minWidth: '150',
		showOverflowTooltip: false,
		align: 'center',
		sortable: true,
	},
	{
		prop: 'serviceFee',
		label: '總金額',
		minWidth: '150',
		showOverflowTooltip: false,
		align: 'center',
		sortable: true,
	},
	{
		prop: 'discountedServiceFee',
		label: '支付金額',
		minWidth: '150',
		showOverflowTooltip: false,
		align: 'center',
		sortable: true,
	},
	{
		prop: 'discountPercentage',
		label: '折扣率',
		minWidth: '150',
		showOverflowTooltip: false,
		align: 'center',
		sortable: true,
	},
	{
		prop: 'discountAmount',
		label: '折扣金額',
		minWidth: '150',
		showOverflowTooltip: false,
		align: 'center',
		sortable: true,
	},
	{
		prop: 'totalWorkingHours',
		label: '預估總工時',
		minWidth: '200',
		showOverflowTooltip: false,
		align: 'center',
		sortable: true,
	},
	{
		prop: 'startTime',
		label: '服務開始時間',
		minWidth: '200',
		showOverflowTooltip: false,
		align: 'center',
		sortable: true,
	},
	{
		prop: 'endTime',
		label: '服務結束時間',
		minWidth: '200',
		showOverflowTooltip: false,
		align: 'center',
		sortable: true,
	},
	{
		prop: 'createUser',
		label: '建單人員',
		minWidth: '150',
		showOverflowTooltip: false,
		align: 'center',
		sortable: true,
	},
	{
		prop: 'createDate',
		label: '建單日期',
		minWidth: '200',
		showOverflowTooltip: false,
		align: 'center',
		sortable: true,
	},
	{
		prop: 'action',
		label: '操作',
		minWidth: '200',
		showOverflowTooltip: true,
		align: 'center',
		render: 'action',
		sortable: false,
	},
]
