<template>
	<div class="popDialog">
		<el-dialog
			v-if="detailedDialog"
			width="95%"
			top="30px"
			:visible.sync="detailedDialog"
			:before-close="closeDialog"
			:fullscreen="dialogFull"
			custom-class="custom-dialog"
		>
			<template v-slot:title>
				<el-row>
					<el-col :span="24" class="selectTitleMax">
						<span>工單詳細資料</span>
					</el-col>
				</el-row>
			</template>
			<el-row>
				<el-col :span="selectTitleSpan" class="detailedTitle">
					<span>會員資料</span>
				</el-col>
			</el-row>
			<el-row>
				<el-col :span="selectTitleSpan" class="detailedTitle">
					<span>姓名:</span>
				</el-col>
				<el-col :span="selectInpudSpan" class="detailed">
					<el-input
						:value="`${info.name || ''} ${info.lastName || ''}`"
						:disabled="true"
						:style="{
							visibility: info.name !== null || info.lastName !== null ? 'visible' : 'hidden',
						}"
					></el-input>
					<!--                    <span>{{info.name}}{{info.lastName}}</span>-->
				</el-col>
				<el-col :span="selectTitleSpan" class="detailedTitle">
					<span>暱稱:</span>
				</el-col>
				<el-col :span="selectInpudSpan" class="detailed">
					<el-input
						:value="`${info.nickName || ''}`"
						:disabled="true"
						:style="{visibility: info.nickName !== null ? 'visible' : 'hidden'}"
					></el-input>
					<!--                    <span>{{info.nickName}}</span>-->
				</el-col>
				<el-col :span="selectTitleSpan" class="detailedTitle">
					<span>性別:</span>
				</el-col>
				<el-col :span="selectInpudSpan" class="detailed">
					<el-input
						:value="`${info.genderString || ''}`"
						:disabled="true"
						:style="{visibility: info.genderString !== null ? 'visible' : 'hidden'}"
					></el-input>
					<!--                    <div style="width: 100%;">-->
					<!--                    <span>{{info.genderString}}</span>-->
					<!--                    </div>-->
				</el-col>
			</el-row>
			<el-row>
				<el-col :span="selectTitleSpan" class="detailedTitle">
					<span>生日:</span>
				</el-col>
				<el-col :span="selectInpudSpan" class="detailed">
					<el-input
						:value="`${info.birthday || ''}`"
						:disabled="true"
						:style="{visibility: info.birthday !== null ? 'visible' : 'hidden'}"
					></el-input>
					<!--                        <span>{{info.birthday}}</span>-->
				</el-col>
				<el-col :span="selectTitleSpan" class="detailedTitle">
					<span>聯絡電話:</span>
				</el-col>
				<el-col :span="selectInpudSpan" class="detailed">
					<el-input
						:value="`${info.phoneNumber || ''}`"
						:disabled="true"
						:style="{visibility: info.phoneNumber !== null ? 'visible' : 'hidden'}"
					></el-input>
					<!--                    <span>{{info.phoneNumber}}</span>-->
				</el-col>
				<el-col :span="selectTitleSpan" class="detailedTitle">
					<span>住址:</span>
				</el-col>
				<el-col :span="selectInpudSpan" class="detailed">
					<el-input
						:value="`${info.address || ''}`"
						:disabled="true"
						:style="{visibility: info.address !== null ? 'visible' : 'hidden'}"
					></el-input>
					<!--                    <span>{{info.address}}</span>-->
				</el-col>
			</el-row>
			<el-row>
				<el-col :span="selectTitleSpan" class="detailedTitle">
					<span>資產資料</span>
				</el-col>
			</el-row>
			<el-row>
				<el-col :span="selectTitleSpan" class="detailedTitle">
					<span>資產品牌:</span>
				</el-col>
				<el-col :span="selectInpudSpan" class="detailed">
					<el-input
						:value="`${assets.brand || ''}`"
						:disabled="true"
						:style="{visibility: assets.brand !== null ? 'visible' : 'hidden'}"
					></el-input>
					<!--                    <span>{{assets.brand}}</span>-->
				</el-col>
				<el-col :span="selectTitleSpan" class="detailedTitle">
					<span>資產類型:</span>
				</el-col>
				<el-col :span="selectInpudSpan" class="detailed">
					<el-input
						:value="`${assets.assetsModel || ''}`"
						:disabled="true"
						:style="{visibility: assets.assetsModel !== null ? 'visible' : 'hidden'}"
					></el-input>
					<!--                    <span>{{assets.assetsModel}}</span>-->
				</el-col>
				<el-col :span="selectTitleSpan" class="detailedTitle">
					<span>資產識別碼:</span>
				</el-col>
				<el-col :span="selectInpudSpan" class="detailed">
					<el-input
						:value="`${assets.identifier || ''}`"
						:disabled="true"
						:style="{visibility: assets.identifier !== null ? 'visible' : 'hidden'}"
					></el-input>
					<!--                    <span>{{assets.identifier}}</span>-->
				</el-col>
			</el-row>
			<el-row>
				<el-col :span="selectTitleSpan" class="detailedTitle">
					<span>價格區間:</span>
				</el-col>
				<el-col :span="selectInpudSpan" class="detailed">
					<el-input
						:value="`${assets.priceRange || ''}`"
						:disabled="true"
						:style="{visibility: assets.priceRange !== null ? 'visible' : 'hidden'}"
					></el-input>
					<!--                    <span>{{assets.priceRange}}</span>-->
				</el-col>
				<el-col :span="selectTitleSpan" class="detailedTitle">
					<span>國內/國外:</span>
				</el-col>
				<el-col :span="selectInpudSpan" class="detailed">
					<el-input
						:value="`${assets.isImportedString || ''}`"
						:disabled="true"
						:style="{visibility: assets.isImportedString !== null ? 'visible' : 'hidden'}"
					></el-input>
					<!--                    <span>{{assets.isImportedString}}</span>-->
				</el-col>
				<el-col :span="selectTitleSpan" class="detailedTitle">
					<span>資產服務等級:</span>
				</el-col>
				<el-col :span="selectInpudSpan" class="detailed">
					<el-input
						:value="`${assets.serviceLevel || ''}`"
						:disabled="true"
						:style="{visibility: assets.serviceLevel !== null ? 'visible' : 'hidden'}"
					></el-input>
					<!--                    <span>{{assets.serviceLevel}}</span>-->
				</el-col>
			</el-row>
			<el-row>
				<el-col :span="selectTitleSpan" class="detailedTitle">
					<span>資格起算日:</span>
				</el-col>
				<el-col :span="selectInpudSpan" class="detailed">
					<el-input
						:value="`${assets.startDate || ''}`"
						:disabled="true"
						:style="{visibility: assets.startDate !== null ? 'visible' : 'hidden'}"
					></el-input>
					<!--                    <span>{{assets.startDate}}</span>-->
				</el-col>
				<el-col :span="selectTitleSpan" class="detailedTitle">
					<span>資格終止日:</span>
				</el-col>
				<el-col :span="selectInpudSpan" class="detailed">
					<el-input
						:value="`${assets.endDate || ''}`"
						:disabled="true"
						:style="{visibility: assets.endDate !== null ? 'visible' : 'hidden'}"
					></el-input>
					<!--                    <span>{{assets.endDate}}</span>-->
				</el-col>
				<el-col :span="selectTitleSpan" class="detailedTitle">
					<span>會員資格狀態:</span>
				</el-col>
				<el-col :span="selectInpudSpan" class="detailed">
					<el-input
						:value="`${assets.status || ''}`"
						:disabled="true"
						:style="{visibility: assets.status !== null ? 'visible' : 'hidden'}"
					></el-input>
					<!--                    <span>{{assets.status}}</span>-->
				</el-col>
			</el-row>
			<el-row>
				<el-col :span="selectTitleSpan" class="detailedTitle">
					<span>會員資格使用:</span>
				</el-col>
				<el-col :span="selectInpudSpan" class="detailed">
					<el-input
						:value="`${assets.isUseString || ''}`"
						:disabled="true"
						:style="{visibility: assets.isUseString !== null ? 'visible' : 'hidden'}"
					></el-input>
					<!--                    <span>{{assets.isUseString}}</span>-->
				</el-col>
				<el-col :span="selectTitleSpan" class="detailedTitle">
					<span>資格變更日:</span>
				</el-col>
				<el-col :span="selectInpudSpan" class="detailed">
					<el-input
						:value="`${assets.statusChangeDate || ''}`"
						:disabled="true"
						:style="{visibility: assets.statusChangeDate !== null ? 'visible' : 'hidden'}"
					></el-input>
					<!--                    <span>{{assets.statusChangeDate}}</span>-->
				</el-col>
				<el-col :span="selectTitleSpan" class="detailedTitle">
					<span>資格購買金額:</span>
				</el-col>
				<el-col :span="selectInpudSpan" class="detailed">
					<el-input
						:value="`${assets.purchaseAmount || ''}`"
						:disabled="true"
						:style="{visibility: assets.purchaseAmount !== null ? 'visible' : 'hidden'}"
					></el-input>
					<!--                    <span>{{assets.purchaseAmount}}</span>-->
				</el-col>
			</el-row>
			<el-row>
				<el-col :span="selectTitleSpan" class="detailedTitle">
					<span>折扣率:</span>
				</el-col>
				<el-col :span="selectInpudSpan" class="detailed">
					<el-input
						:value="`${assets.discountPercentage || ''}`"
						:disabled="true"
						:style="{visibility: assets.discountPercentage !== null ? 'visible' : 'hidden'}"
					></el-input>
					<!--                    <span>{{assets.discountPercentage}}</span>-->
				</el-col>
				<el-col :span="selectTitleSpan" class="detailedTitle">
					<span>聯絡人:</span>
				</el-col>
				<el-col :span="selectInpudSpan" class="detailed">
					<el-input
						:value="`${assets.contactName || ''}`"
						:disabled="true"
						:style="{visibility: assets.contactName !== null ? 'visible' : 'hidden'}"
					></el-input>
					<!--                    <span>{{assets.contactName}}</span>-->
				</el-col>
				<el-col :span="selectTitleSpan" class="detailedTitle">
					<span>聯絡人電話:</span>
				</el-col>
				<el-col :span="selectInpudSpan" class="detailed">
					<el-input
						:value="`${assets.contactPhoneNumber || ''}`"
						:disabled="true"
						:style="{visibility: assets.contactPhoneNumber !== null ? 'visible' : 'hidden'}"
					></el-input>
					<!--                    <span>{{assets.contactPhoneNumber}}</span>-->
				</el-col>
			</el-row>
			<el-row>
				<el-col :span="selectTitleSpan" class="detailedTitle">
					<span>照片1:</span>
				</el-col>
				<el-col :span="selectInpudSpan" class="detailed">
					<img :src="'data:image/jpg;base64,' + assets.photoBase64_1" @click="showImage(assets.photoBase64_1)" />
				</el-col>
				<el-col :span="selectTitleSpan" class="detailedTitle">
					<span>照片2:</span>
				</el-col>
				<el-col :span="selectInpudSpan" class="detailed">
					<img :src="'data:image/jpg;base64,' + assets.photoBase64_2" @click="showImage(assets.photoBase64_2)" />
				</el-col>
				<el-col :span="selectTitleSpan" class="detailedTitle">
					<span>照片3:</span>
				</el-col>
				<el-col :span="selectInpudSpan" class="detailed">
					<img :src="'data:image/jpg;base64,' + assets.photoBase64_3" @click="showImage(assets.photoBase64_3)" />
				</el-col>
			</el-row>
			<div v-if="currentImage" class="image-overlay">
				<button @click="closeImage" class="el-dialog__headerbtn">
					<i class="el-dialog__close el-icon el-icon-close"></i>
				</button>
				<!-- <button @click="closeImage" class="close-button">&times;</button> -->
				<img :src="'data:image/jpg;base64,' + currentImage" class="image-size" />
			</div>
			<!-- <el-row>
				<el-col :span="selectInpudSpan">
					<div v-if="currentImage" class="image-overlay">
						<button @click="closeImage" class="close-button">&times;</button>
						<img :src="'data:image/jpg;base64,' + currentImage" class="image-size" />
					</div>
				</el-col>
			</el-row> -->
			<el-row>
				<el-col :span="selectTitleSpan" class="detailedTitle">
					<span>服務項目資料</span>
				</el-col>
			</el-row>
			<el-row>
				<el-col :span="24">
					<TableTemplate
						ref="tableTemplate"
						:tableData="tableData"
						:columns="columns"
						@selectOrderWorkUser="selectOrderWorkUser"
					></TableTemplate>
				</el-col>
			</el-row>
		</el-dialog>
	</div>
</template>

<script>
import TableTemplate from '@/components/element/TableTemplate.vue'
import tableColumns from '@/assets/js/orderDetailedTable.js'

export default {
	name: 'detailedDialog',
	components: {
		TableTemplate,
	},
	props: {
		detailedDialog: null,
	},
	data() {
		return {
			dialogFull: false,
			row: null,
			token: null,
			companyId: null,
			//表頭設定
			columns: [],
			//table數據
			tableData: [],
			reload: false,
			info: null,
			assets: null,
			currentImage: null,
			orderWorkData: [],
			orderWorkUserData: null,
			orderWorkUserDialog: false,
		}
	},
	mounted() {
		this.columns = tableColumns
	},
	methods: {
		init(row) {
			this.info = row.info
			this.assets = row.assets
			this.tableData = row.orderWorkList
			console.log('assets=', this.assets)
			const loginReturnDto = localStorage.getItem('loginReturnDto')
			if (loginReturnDto != null && JSON.parse(loginReturnDto) != null) {
				this.loginReturnDto = JSON.parse(loginReturnDto)
				this.token = this.loginReturnDto.token
				this.companyId = this.loginReturnDto.companyId
				//console.log("editCompanyDialog取得token=" + this.token);
			}
		},
		loadingView() {
			//開啟載入特效
			this.loadingStatus = this.$loading({
				lock: true,
				text: 'Loading',
				spinner: 'el-icon-loading',
				background: 'rgba(0, 0, 0, 0.7)',
			})
		},
		closeDialog() {
			console.log('關閉Pop')
			this.$emit('update:reload', this.reload)
			this.$emit('closeDialog')
		},
		closeLoading() {
			//關閉載入特效
			if (this.loadingStatus != null) {
				this.loadingStatus.close()
			}
		},
		showImage(base64Image) {
			this.currentImage = base64Image // 设置当前显示的图片 Base64 字符串
		},
		closeImage() {
			this.currentImage = null
		},
		async selectOrderWorkUser(row) {
			console.log('detailedDialog selectOrderWorkUser row=', row)
			this.$emit('selectOrderWorkUser', row)
		},
	},
}
</script>

<style scoped>
img {
        width: 50px;
        height: 50px;
        /*height: auto;*/
        margin: 10px 0px 0px 0px;
    }

    .image-overlay {
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background-color: rgba(255, 255, 255, 0.9);
        z-index: 9999;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 200px;
        height: 200px;
        max-width: 100%;
        max-height: 100%;
    }

    .close-button {
        position: absolute;
        top: 5px;
        right: 10px;
        font-size: 30px;
        background: transparent;
        border: none;
        color: darkred;
        cursor: pointer;
    }

    .image-size {
        width: 200px;
        height: 200px;
        max-width: 100%;
        max-height: 100%;
    }

		.el-dialog__headerbtn {
    position: absolute;
    top: 15px;
    right: 15px;
    padding: 0;
    background: 0 0;
    border: none;
    outline: 0;
    cursor: pointer;
    font-size: 16px;
	}

	/* 使用 ::v-deep 深度选择器 */
::v-deep .el-input__inner {
  border: none !important; /* 去除外框线 */
  background-color: transparent !important; /* 设置背景色为透明 */
  padding: 0 !important; /* 去除内部的 padding */
  box-shadow: none !important; /* 去除阴影效果 */
}
</style>
